// color variants
@import 'themes-vars.module.scss';

// third-par
@import ' ~react-perfect-scrollbar/dist/css/styles.css';
@import ' ~slick-carousel/slick/slick.css';
@import ' ~slick-carousel/slick/slick-theme.css';

@import ' ~react-18-image-lightbox/style.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.preBuildDashBoard-slider {
    overflow: hidden;
    .slider {
        .slide {
            opacity: 0.5;
            display: flex;
            justify-content: center;
            .custom-slider {
                gap: 40px;
            }
            &.selected {
                opacity: 1;
                .custom-slider {
                    display: flex;
                    flex-direction: column;
                }
            }
            &:not(.selected) {
                transform: scale(0.7);
                transform-origin: top;
            }
        }
    }
}

.project-info {
    .project-slider {
        .slick-list {
            padding: 0 !important;
        }
    }
    .slick-slide {
        opacity: 0.05;
    }
    .slick-active {
        opacity: 0.2;
    }
    .slick-current {
        opacity: 1 !important;
        .MuiTypography-root {
            color: $primaryMain;
        }
    }
}

.ReactModal__Overlay {
    z-index: 99999 !important;
}


input, textarea{
    background:#F5F5F5!important;
    border: 0!important;
    outline:none!important;
    font-size: 14px;
}
.ql-toolbar, .ql-editor, .MuiSelect-select{
    background:#F5F5F5;
    border: 0;
    outline: none;
}
.ql-toolbar{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.ql-toolbar.ql-snow{
    border:0px;
    border-bottom: 1px solid #ccc;
}
.ql-container.ql-snow{
    border:0px!important;
}
.ql-container, .ql-editor{
    border-top: 0px solid #f3f3f3!important;
    z-index: 999;
    border-bottom-left-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
}
.untitled_job{
    background-color: transparent!important;
}
.borderBottom{
    border-bottom: 1px solid #f2f2f2;
}
.grayBg{
    background: #f5f5f5;
    color: #333333;
}
.grayBg:hover{
    background: #f5f5f5;
    color: #333333;
}
.deepPurpleText{
    color: $deepPurple500!important;
}
.deepPurpleBg{
    background: $deepPurple500!important;
    color:#ffffff!important
}
.deepPurpleBg:hover{
    background: $deepPurple600!important;
    color:#ffffff!important
}
.bigButton{
    height: 40px;
    padding-left:15px;
    padding-right:15px;
    margin-bottom:15px;
}
.marginTop{
    margin-top:15px;
}
.MuiInputBase-root{
    fieldset{
        display: none;
    }
}
.ql-editor{
    min-height: 250px;
}
.trending {
    position: relative;
    font-size: 9px;
    top: 0px;
    left: 15px;
    letter-spacing: 0.03em;
    line-height: 16px;
    width: 25px;
    height: 16px;
    font-family: roboto;
    color: #00c853;
    text-align: center;
}
.trending-outer {
    position: relative;
    border-radius: 10px;
    background-color: #b9f6ca;
    width: 70px;;
    height: 15px;
    margin-bottom: 8px;
}
.try-it-outer {
    position: relative;
    border-radius: 4px;
    background-color: #673ab7;
    width: 47px;
    height: 24px;
}

.search-templates {
    position: absolute;
    margin: 0 !important;
    height: 57.78%;
    width: 28.66%;
    top: 26.67%;
    left: 8.28%;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    z-index: 0;
}
.search-icon {
    position: absolute;
    margin: 0 !important;
    top: 14px;
    left: 9px;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
}
.input {
    margin: 0 !important;
    position: absolute;
    top: -2px;
    left: 2px;
    border-radius: 12px;
    background-color: #fff;
    width: 471px;
    height: 45px;
    display: flex;
    flex-direction: row;
    padding: 0px 16px 14px 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    z-index: 0;
}
.catalog {
    position: absolute;
    margin: 0 !important;
    top: 8px;
    left: 382px;
    font-size: 16px;
    font-weight: 500;
    color: #29314f;
    z-index: 1;
}
.expand-icon {
    position: absolute;
    margin: 0 !important;
    top: 8px;
    left: 440px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}
.input-parent {
    width: 476px;
    display: flex;
    flex-direction: row;
    padding: 0px 7px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.frame-parent {
    position: relative;
    width: 100%;
    height: 48px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    font-size: 14px;
    color: #616161;
    font-family: Roboto;
}
.slick-list{
    @media only screen and (max-width: 767px) {
        height: 80vh !important;
    }
}


