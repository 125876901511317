.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: #fff;
    width: 274px;
    height: 150px;
}
.codingAssessmentParent {
    position: absolute;
    top: 7px;
    left: 13px;
    width: 251px;
    height: 135px;
}
.publishedAt231 {
    position: absolute;
    top: 21px;
    left: 39px;
    letter-spacing: 0.03em;
    line-height: 16px;
    color: #757575;
}
.messageIcon1 {
    position: absolute;
    height: 23.7%;
    width: 12.75%;
    top: 3.7%;
    right: 87.25%;
    bottom: 72.59%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.groupItem {
    position: absolute;
    top: 95px;
    left: 214px;
    width: 38.15px;
    height: 38.15px;
    object-fit: cover;
}
.verticalMoreIcon1 {
    position: absolute;
    top: 8px;
    left: 235px;
    width: 16px;
    height: 16px;
    overflow: hidden;
}
.tagsSoftwareEngineering1 {
    position: absolute;
    top: 51px;
    left: 1px;
    letter-spacing: 0.03em;
    line-height: 16px;
    text-align: left;
    display: inline-block;
    width: 228px;
}
.questions {
    position: absolute;
    top: 0px;
    left: 0px;
    letter-spacing: 0.03em;
    line-height: 16px;
}
.div {
    position: absolute;
    top: 20px;
    left: 17px;
    letter-spacing: 0.03em;
    line-height: 16px;
}
.questionsParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 48px;
    height: 36px;
}
.div1 {
    position: absolute;
    top: 20px;
    left: 13px;
    letter-spacing: 0.03em;
    line-height: 16px;
}
.responsesParent {
    position: absolute;
    top: 0px;
    left: 64px;
    width: 52px;
    height: 36px;
}
.groupParent {
    position: absolute;
    top: 99px;
    left: 1px;
    width: 116px;
    height: 36px;
}
.groupInner {
    position: absolute;
    top: 3px;
    left: 0px;
    border-radius: 8px;
    background-color: #b9f6ca;
    width: 32px;
    height: 10px;
}
.published {
    position: absolute;
    top: 0px;
    left: 2px;
    letter-spacing: 0.03em;
    line-height: 16px;
}
.rectangleGroup {
    position: absolute;
    top: 0px;
    left: 174px;
    width: 32px;
    height: 16px;
    font-size: 6px;
    color: #00c853;
}
.rectangleParent {
    position: relative;
    width: 44%;
    height: 150px;
    text-align: center;
    font-size: 10px;
    color: #29314f;
    font-family: Roboto;
}

