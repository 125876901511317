.homeIcon1 {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}
.assessments {
    position: relative;
    font-weight: 500;
}
.homeParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}
.rectangleDiv {
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    width: 100%;
    height: 1054px;
    padding: 30px;
}


.nextIcon1 {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.frameParent {
    margin: 0 !important;
    position: absolute;
    top: 11px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    z-index: 0;
}
.breadcrumb {
    position: relative;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    padding: 0px 16px 8px 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 12px;
    color: #29314f;
    font-family: Roboto;
}
.editIcon1 {
    position: absolute;
    top: 5px;
    left: 205px;
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.unititledAssessment {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 28px;
}
.editParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 225px;
    height: 28px;
}
.groupParent {
    position: absolute;
    width: 100%;
    height: 28px;
    text-align: right;
    font-size: 20px;
    color: #29314f;
    font-family: Roboto;
}

.shareIcon1 {
    position: absolute;
    top: 2px;
    left: 0px;
    width: 16px;
    height: 16px;
    overflow: hidden;
}
.share {
    position: absolute;
    top: 0px;
    left: 20px;
    line-height: 20px;
}
.shareParent {
    position: absolute;
    top: 0px;
    left: 1000px;
    width: 60px;
    height: 20px;
}
.instructions {
    position: absolute;
    top: 0px;
    left: 24px;
    line-height: 20px;
}
.descriptionIcon1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.instructionsParent {
    position: absolute;
    top: 0px;
    left: 650px;
    width: 99px;
    height: 20px;
}
.managePermissions {
    position: absolute;
    top: 0px;
    left: 23px;
    width: 200px;
    line-height: 20px;
}
.friendsIcon1 {
    position: absolute;
    top: 1px;
    left: 40px;
    width: 16px;
    height: 16px;
    overflow: hidden;
}
.managePermissionsParent {
    position: absolute;
    top: 0px;
    left: 750px;
    width: 155px;
    height: 20px;
}
.preview {
    position: absolute;
    top: 0px;
    left: 19px;
    line-height: 20px;
}
.viewIcon1 {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 12px;
    height: 12px;
    overflow: hidden;
}
.previewParent {
    position: absolute;
    top: 0px;
    left: 1100px;
    width: 68px;
    height: 20px;
}


.leftColumn {
    text-align: right;
}

.rightColumn {
    text-align: right;
}