.homeIcon1 {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}
.assessments {
    position: relative;
    font-weight: 500;
}
.homeParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}
.rectangleDiv {
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    width: 100%;
    height: 1054px;
    padding: 30px;
}


.nextIcon1 {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}
.frameParent {
    margin: 0 !important;
    position: absolute;
    top: 11px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    z-index: 0;
}
.breadcrumb {
    position: relative;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    height: 46px;
    display: flex;
    flex-direction: row;
    padding: 0px 16px 8px 0px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-size: 12px;
    color: #29314f;
    font-family: Roboto;
}
.assessmentContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
}
.editIcon1 {
    position: absolute;
    top: 5px;
    left: 205px;
    width: 20px;
    height: 20px;
    overflow: hidden;
}
.unititledAssessment {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 28px;
}
.editParent {
    position: absolute;
    top: 0px;
    left: 1px;
    width: 225px;
    height: 28px;
    font-size: 20px;
}
.description {
    position: absolute;
    top: 0px;
    left: 0px;
    line-height: 24px;
    font-weight: 500;
}
.autoGenerate {
    position: absolute;
    top: 0px;
    left: 22px;
    line-height: 20px;
    font-weight: 500;
}
.iconAutoFixHigh2 {
    position: absolute;
    height: 60%;
    width: 10.71%;
    top: 15%;
    right: 89.29%;
    bottom: 25%;
    left: 0%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}
.autoGenerateParent {
    position: absolute;
    top: 0px;
    left: 957px;
    width: 112px;
    height: 20px;
    font-size: 14px;
    color: #673ab7;
}
.descriptionParent {
    position: absolute;
    top: 0px;
    left: 1px;
    width: 1069px;
    height: 24px;
}
.weAreExcited1 {
    margin: 0;
}
.weAreExcitedContainer {
    position: absolute;
    top: 39px;
    left: 0px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    width: 890px;
}
.groupDiv {
    position: absolute;
    top: 54px;
    left: 0px;
    width: 1070px;
    height: 139px;
}
.autoGenerateGroup {
    position: absolute;
    top: 0px;
    left: 958px;
    width: 112px;
    height: 20px;
    font-size: 14px;
    color: #673ab7;
}
.instructionsParent {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1070px;
    height: 24px;
}
.blankLine {
    margin: 0;
    font-weight: 500;
}
.timeTheTestIsTimedMake {
    margin-bottom: 0px;
}
.environmentFindAQuietAnd {
    margin: 0;
    padding-left: 19px;
}
.beforeYouBeginContainer {
    position: absolute;
    top: 39px;
    left: 0px;
    font-size: inherit;
    line-height: 20px;
    display: inline-block;
    width: 890px;
}
.groupParent1 {
    position: absolute;
    top: 217px;
    left: 0px;
    width: 1070px;
    height: 639px;
}
.groupParent {
    position: relative;
    width: 100%;
    height: 856px;
    text-align: left;
    font-size: 16px;
    color: #29314f;
    font-family: Roboto;
}

