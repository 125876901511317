.groupChild {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    background-color: #ede7f6;
    border: 1px dashed #673ab7;
    box-sizing: border-box;
    width: 274px;
    height: 150px;
}
.addCircleIcon {
    position: absolute;
    top: 42px;
    left: 105px;
    width: 51px;
    height: 51px;
    overflow: hidden;
}
.createNewAssessment1 {
    position: absolute;
    top: 108px;
    left: 6px;
    display: inline-block;
    width: 249px;
}
.rectangleParent {
    position: relative;
    width: 10%;
    margin-right: 1%;
    height: 150px;
    z-index: 1;
    text-align: center;
    font-size: 12px;
    color: #673ab7;
    font-family: Inter;
}

